import makeFAIcon from "utils/makeFAIcon";

import { faShoppingCart, faLaptop, faLock, faTools } from "@fortawesome/free-solid-svg-icons";
import { faUncharted } from "@fortawesome/free-brands-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);


export const UnchartedIcon = makeFAIcon(faUncharted);
export const ToolsIcon = makeFAIcon(faTools);